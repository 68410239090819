import { HTTP } from "../../service/http-common";
// initial state
const state = () => ({
   is_auth: false,
   token: "",
   user: {
      id: "",
      employee_id: "",
      name: "",
      email: "",
      roles: [],
      permissions: [],
   },
});

// getters
const getters = {
   isAuth(state) {
      return state.is_auth;
   },
   getToken(state) {
      return state.token;
   },
   getAuthName(state) {
      return state.user.name;
   },
   getAuthId(state) {
      return state.user.id;
   },
   getAuthEmpId(state) {
      return state.user.employee_id;
   },
   getAuthEmail(state) {
      return state.user.email;
   },
   getRoles(state) {
      return state.user.roles;
   },
   getPermissions(state) {
      return state.user.permissions;
   },
};

// actions
const actions = {
   login({ commit }, input) {
      return new Promise((resolve, reject) => {
         const bodyParameter = {
            email: input.email,
            password: input.password,
         };

         HTTP.post("auth/v1/login", bodyParameter)
            .then((response) => {
               commit("setToken", "Bearer " + response.data.data.access_token);
               commit("setAuthUser", response.data.data);
               commit("setAuth", true);
               resolve(response);
            })
            .catch((error) => {
               reject(error.response.data.errors);
            });
      });
   },
   logout({ commit }) {
      return new Promise((resolve, reject) => {
         const user = {
            name: "",
            id: "",
            employee_id: "",
            email: "",
            roles: [],
            permissions: [],
         };

         HTTP.post("auth/v1/logout", "")
            .then((response) => {
               commit("setToken", "");
               commit("setAuthUser", user);
               commit("setAuth", false);
               resolve(response);
            })
            .catch((error) => {
               commit("setToken", "");
               commit("setAuthUser", user);
               commit("setAuth", false);
               reject(error.response.data.errors);
            });
      });
   },
   changePassword(_, input) {
      return new Promise((resolve, reject) => {
         const bodyParameter = {
            new_password: input.newPassword,
            confirm_new_password: input.confirmNewPassword,
         };

         HTTP.post("auth/v1/change-password", bodyParameter)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error.response.data.errors);
            });
      });
   },
};

// mutations
const mutations = {
   setToken(state, token) {
      state.token = token;
   },
   setAuth(state, payload) {
      state.is_auth = payload;
   },
   setAuthUser(state, payload) {
      state.user.name = payload.name;
      state.user.id = payload.id;
      state.user.employee_id = payload.employee_id;
      state.user.email = payload.email;
      state.user.roles = payload.roles;
      state.user.permissions = payload.permissions;
   },
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
