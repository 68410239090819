<template>
   <div>
      <p class="card-text placeholder-glow">
         <span class="placeholder col-7 me-1"></span>
         <span class="placeholder col-4"></span>
         <span class="placeholder col-4 me-1"></span>
         <span class="placeholder col-6"></span>
         <span class="placeholder col-8"></span>
      </p>
   </div>
</template>