const authRoutes = [
   {
      path: "/login",
      name: "login",
      component: () => import("../../pages/auth/Login.vue"),
      meta: { isAuthRoute: true },
   },
];

export default authRoutes;
