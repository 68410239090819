import { createWebHistory, createRouter } from "vue-router";
import adminRoutes from "./admin";
import authRoutes from "./auth";
import pageNotFoundRoute from "./pageNotFound";

const baseRoutes = [
   {
      path: "/admin",
      name: "admin-home",
      component: () => import("../components/layouts/Master.vue"),
      meta: {
         requiresAuth: true,
         // requirePermissions: true,
         // permissions: [
         //   "user-list",
         //   "user-create",
         //   "user-edit",
         //   "role-list",
         //   "role-create",
         //   "role-edit",
         //   "permission-list",
         //   "permission-create",
         //   "permission-edit",
         // ],
      },
      children: adminRoutes,
   },
   //   {
   //     path: "/",
   //     name: "home",
   //     component: () => import("../components/layouts/Master.vue"),
   //   },
];

const routes = baseRoutes.concat(authRoutes).concat(pageNotFoundRoute);

const router = createRouter({
   history: createWebHistory(),
   routes,
   linkActiveClass: "activeClass",
   linkExactActiveClass: "activeClass",
});

export default router;
